// 列头配置
const columns = [{
    field: 'rowIndex',
    headerName: '序号',
    width: 55,
    cellClass: 't-c'
  },
  {
    field: 'CompanyName',
    headerName: '客户名称',
    editable: false,
    width: 120,
    cellClass: 't-l'
  },
  {
    field: 'CompanyCode',
    headerName: '客户代码',
    editable: false,
    width: 90,
    cellClass: 't-c'
  },
  {
    field: 'CooperationGrade',
    headerName: '合作等级',
    editable: false,
    width: 90,
    cellClass: 't-c'
  },
  {
    field: 'CustomerType',
    headerName: '客户类型',
    editable: false,
    width: 100,
    cellClass: 't-c',
    valueFormatter: ({ data: { CustomerType } }) => {
      let ret = ''
      switch (CustomerType){
        case 'AddInquiry':
          ret = '新增询价客户'
          break;
        case 'Commonly':
          ret = '一般客户'
          break;
        case 'StressFollow':
          ret = '重点跟进客户'
          break;
        case 'StressProtect':
          ret = '重点保护客户'
          break;
      }
      return ret;
    }
  },
  {
    field: 'FollowUpContent',
    headerName: '最近跟进记录',
    editable: false,
    width: 120,
    cellClass: 't-c'
  },
  {
    field: 'FollowUpTime',
    headerName: '最近跟进时间',
    editable: false,
    width: 120,
    cellClass: 't-c'
  },
  {
    field: 'NoFollowUpDays',
    headerName: '未跟进天数',
    editable: false,
    width: 90,
    cellClass: 't-c'
  },
  {
    field: 'NoOrderDays',
    headerName: '未下单天数',
    editable: false,
    width: 90,
    cellClass: 't-c',
  },
  {
    field: 'CreatedTime',
    headerName: '创建时间',
    editable: false,
    width: 120,
    cellClass: 't-c'
  },
  {
    field: 'AfferentTime',
    headerName: '传入时间',
    editable: false,
    width: 120,
    cellClass: 't-c'
  },
  {
    field: 'FrontDuty',
    headerName: '前负责人',
    editable: false,
    width: 95,
    cellClass: 't-c'
  },
  {
    field: 'SeaRetentionDay',
    headerName: '公海滞留天数',
    editable: false,
    width: 100,
    cellClass: 't-c'
  },
  {
    field: 'Province',
    headerName: '省',
    editable: false,
    width: 80,
    cellClass: 't-c'
  },
  {
    field: 'City',
    headerName: '市',
    editable: false,
    width: 80,
    cellClass: 't-c',

  },
  {
    field: 'Area',
    headerName: '区',
    editable: false,
    width: 80,
    cellClass: 't-c'
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 153,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]
const seaConfig = [{
    field: "Name",
    headerName: "客户名称",

    editable: false,
    cellClass: "t-c",
  },
  {
    field: "operation",
    headerName: "操作",
    editable: false,

    cellClass: "t-c",
    cellRendererFramework: "GridCellButtonRender",
  },
]
export {
  columns,
  seaConfig
}
