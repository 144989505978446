<template>
  <div class="c-seaCustomer-view">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="`${editItem.typeName}客户`"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section>
        <div v-if="editItem.typeName === '清洗'">
          <p class="m-clean-p"><span>清洗内容（可多选）：</span></p>
          <el-checkbox v-model="isFollowUp">清洗所有跟进记录</el-checkbox>
          <el-checkbox v-model="isReceive">清洗所有领取记录</el-checkbox>
          <p><span>将对如下客户进行清洗，清洗数据不可还原，请确认！</span></p>
        </div>

        <div v-else>
          <znl-input
            :border="true"
            :clearable="true"
            :height="32"
            ref="input"
            :select-options="selectSource"
            form-type="select"
            :placeholder="`${
              editItem.typeName === '分配' ? '人员' : '公海'
            }名称`"
            :title="`接受${editItem.typeName === '分配' ? '人员' : '公海'}:`"
            title-width="60px"
            v-model="editItem.isSelectId"
            width="240px"
          >
          </znl-input>
          <p>
            <span>将对如下客户进行{{ editItem.typeName }}，请确认！</span>
          </p>
        </div>

        <div class="m-grid-list">
          <grid
            ref="sealist"
            :columns="columns"
            :datas="editItem.itemSource"
            :selection="false"
            :suppressDragLeaveHidesColumns="true"
            :suppressPaginationPanel="false"
            domLayout="autoHeight"
            headerHeight="35"
            type="base"
          >
          </grid>
        </div>
      </section>
      <section class="m-dialog-footer c-seaCustomer-footer">
        <el-button type="primary" @click="onSave">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { seaConfig } from "./config";
import { getCommonList } from "@/api/seaConfig";
export default {
  name: "seaCustomerConfig",
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      title: "客户",
      columns: seaConfig,
      type: 1,
      selectSource: [],
      editItem: {
        itemSource: [],
        typeName: "清洗",
        isSelectId: 0,
      },
      isFollowUp: false,
      isReceive: false,
    };
  },
  methods: {
   async onOpen(data) {
      // Object.keys(this.editItem).forEach((key) => {
      //   this.editItem[key] = data[key];
      // });
     this.editItem = { ...data, isSelectId: '' }
      if (data.typeName === "分配") {
        this.selectSource = this.systemUser();
      } else if (data.typeName === "转移") {
        let allSeaPool = await getCommonList();
        this.selectSource = allSeaPool.map((item) => {
          return {
            key: item.SeaID,
            value: item.Name,
          };
        });
      }
     this.$refs.input && this.$refs.input.clear()
      this.initColumns();
      this.visible = true;
    },
    onSave() {
      let choiceList = this.editItem.itemSource.filter((m) => m.IsChoice);
      let paramsList = {};
      if (choiceList) {
        paramsList.CompanyIds = choiceList.map((m) => m.Id);
      }
      if (this.editItem.typeName === "清洗") {
        paramsList.CleanFollowUp = this.isFollowUp;
        paramsList.CleanReceive = this.isReceive;
      } else {
        paramsList.SelectId = this.editItem.isSelectId;
      }
      paramsList.ClickType = this.editItem.typeName;
      this.$emit("onEditConfig", paramsList);
      this.visible = false;
    },
    initColumns() {
      const self = this;
      const handleColumn = seaConfig.find((item) => item.field === "operation");
      if (handleColumn) {
        handleColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$nextTick(() => {
        this.$refs.sealist.changeColumns(seaConfig);
      });
    },
    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const type = data.IsChoice;
      const choiceBtn = {
        text: type ? "移除选择" : "选择",
        click() {
          self.onOpenSelect(data);
        },
      };
      buttons.push(choiceBtn);
      return buttons;
    },
    onOpenSelect(data) {
      data.IsChoice = !data.IsChoice;
      this.$refs.sealist.updateRowData(data);
    },
    systemUser() {
      const data = this.$store.state.common.SystemUser;
      return data.map((item) => {
        return {
          key: item.UserID,
          value: item.UserName,
        };
      });
    },
    handleDrag() {
      // 拖拽事件
    },
  },
};
</script>

<style lang="scss">
.c-seaCustomer-view {
  .m-clean-p {
    margin-top: 0px;
  }
  p {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    //color: #303133
  }
  .c-seaCustomer-footer {
    padding-top: 10px;
  }

  .el-dialog {
    min-width: 600px !important;
    width: 650px !important;
  }
}
</style>
