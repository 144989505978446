<template>
  <div class="p-common-list">
    <div class="p-common-list_box">
      <el-tabs v-model="Id" type="border-card">
        <el-tab-pane  v-for="item in commonList" :label="item.name" :key="item.code" >
          <!--        <general-view :ref="item.code" :item="item" :Id="getId"></general-view>-->
        </el-tab-pane>
        <general-view :ref="commonList[Id].code" :item="commonList[Id]" :Id="getId"></general-view>
      </el-tabs>
    </div>
<!--    <general-view :ref="commonList[Id].code" :item="commonList[Id]" :Id="getId"></general-view>-->
  </div>
</template>
<script>
import generalView from "./generalView";
import {getMenu} from "../../api/seaConfig";

export default {
  name: 'CommonListView',
  components: {
    generalView
  },
  data() {
    return {
      value: 1,
      Id: '0',
      commonList: []
    }
  },
  computed: {
    getId() {
      return Number(this.commonList[this.Id].key)
    }
  },
  methods: {
    async onGetMenuList() {
      const data = await getMenu()
      this.commonList = data.map(item => {
        return {
          key: item.OpenSeaId,
          name: item.OpenSeaName,
          code: `sea_${item.OpenSeaId}`
        }
      })
    }
  },
  async created() {
    await this.onGetMenuList()
  }
}
</script>
<style lang="scss">
.p-common-list {
  box-sizing: border-box;
  height: 100%;
  border: 1px ;
  flex: 1;
  display: flex;

  .p-common-list_box {
    flex: 1;
    display: flex
  }

  .el-tabs {
    flex: 1;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .el-tabs__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .el-tab-pane {
    flex: 1;
  }
}
</style>
