<template>
  <div class="m-grid p-general">
    <section class="p-list-search">
      <div class="p-input-search">
        <znl-input v-model="searchData.Name" border clearable placeholder="客户名称" width="130px"></znl-input>
        <znl-input v-model="searchData.CooperationGrade" :select-options="[]" border clearable form-type="select"
                   placeholder="客户等级" width="130px"></znl-input>
        <!-- <znl-input v-model="searchData.AfferentTime" border clearable form-type="datepicker" placeholder="传入时间"
                   width="130px"></znl-input> -->
                   <znl-input
                    v-model="searchData.AfferentTime"
                    border
                    clearable
                    date-type="daterange"
                    form-type="datepicker"
                   value-format="yyyy-MM-dd HH:mm:ss"
                    tabindex="4"
                    placeholder="传入时间">
                  </znl-input>
        <znl-input v-model="searchData.Mobile" border clearable placeholder="手机号" width="130px"></znl-input>
        <znl-input v-model="searchData.ClintAttribute" :select-options="[]" border clearable form-type="select"
                   placeholder="客户属性" width="130px"></znl-input>
        <znl-input v-model="searchData.Phone" border clearable placeholder="固定电话" width="130px"></znl-input>
        <znl-input v-model="searchData.Province" border clearable placeholder="所在省" width="130px"></znl-input>
        <znl-input v-model="searchData.City" border clearable placeholder="所在市" width="130px"></znl-input>
        <znl-input v-model="searchData.Area" border clearable placeholder="所在区" width="130px"></znl-input>
      </div>
      <div class="m-grid-search">
        <el-button class="m-grid-btn" type="primary" @click="onSearch()">查询</el-button>
        <el-button class="m-grid-btn" plain type="primary" @click="onResetData">重置</el-button>
      </div>
    </section>
    <div class="m-grid-search" style="margin-bottom: 10px" v-if="isAllBtn">
      <el-button class="m-grid-btn" type="primary" @click="onButProtect" v-can="'common_list:protect'">保护</el-button>
      <el-button class="m-grid-btn" type="primary" @click="onAllocation('分配')" v-can="'common_list:allocation'">分配</el-button>
      <el-button class="m-grid-btn" type="primary" @click="onAllocation('转移')" v-can="'common_list:transfer'">转移</el-button>
      <el-button class="m-grid-btn" type="primary" @click="onAllocation('清洗')" v-can="'common_list:cleanout'">清洗</el-button>
    </div>
    <section class="m-grid-list p-sealist-bd">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.limit"
        :selection="true"
        @onColumnMoved="onColumnMoved"
        :total-count="pagination.total"
        headerHeight="35"
        type="base">
      </grid>
    </section>
    <sea-customer-config
      ref="editView"
      @onEditConfig="onEditConfig"
    ></sea-customer-config>
  </div>
</template>

<script>
import {columns} from "./modules/config";
import {
  getList,
  getAllClientList,
  protectClient,
  editButttonSeaClient,
} from "@/api/seaConfig";
import seaCustomerConfig from "./modules/seaCustomerConfig.vue";
import {distribution, transfer} from "@/api/config";

import { userColumnMixin } from "@/components/Mixins/UserColumnMixin"
const TableName = "customer-openSea-list"

export default {
  mixins: [userColumnMixin],
  components: {
    seaCustomerConfig,
  },
  computed: {

    isAllBtn() {
      const accessPoint = [
        'common_list:protect',
        'common_list:allocation',
        'common_list:transfer',
        'common_list:cleanout',
      ]
      return accessPoint.some(item => this.can(item))
    }
  },
  props: {
    item: {
      type: Object
    },
    Id: [Number]
  },
  data() {
    return {
      columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        limit: 20,
        total: 40,
      },
      OpenSeaId: null,
      searchData: {
        OpenSeaId: null,      // 菜单
        Name: "",             //客户名称
        CooperationGrade: "", //合作等级
        AfferentTime: [],     // 传入时间
        Mobile: "",           // 手机号
        ClintAttribute: "",   //客户属性
        Phone: "",            //固定电话
        Province: "",         // 省
        City: "",             //市
        Area: "",             //区
      },
      allCommonConfig: [],
    };
  },
  methods: {
    async onSearch(page = 1) {
      const {pagination, searchData, OpenSeaId} = this;
      let sendData = {
        Page: page,
        Limit: pagination.limit,
      };
      Object.assign(sendData, searchData)
      sendData.OpenSeaId = this.Id
      this.searchData.OpenSeaId = this.Id
      console.log(sendData)
      const data = await getList(sendData);
      pagination.page = data.Page;
      pagination.total = data.Total;
      let list = data.Items;
      list.forEach((item, index) => {
        item.rowIndex = index + 1;
      });
      this.itemSource = list;
    },
    //保护
    async onProtectApi(data) {
      let result = await protectClient(data);
      if (result === "success") {
        this.$message.success("保护客户成功！");
      } else {
        this.$message.error(result); // message = `保护客户失败，捞取数量达到限制！(${result.Count}/${result.Count})`;
      }
      await this.onSearch(this.pagination.page);
    },
    async onButProtect() {
      let params = {};
      const selectRow = this.$refs.list.getSelectedRows();
      if (selectRow.length === 0) {
        this.$message.warning("请选择数据保护");
        return
      }
      params.CompanyIds = selectRow.map((x) => x.Id);
      params.OpenSeaID = this.Id
      await this.onProtectApi(params);
    },
    async onTableProtect(data) {
      let params = {};
      let CompanyIds = [];
      CompanyIds.push(data.Id);
      params.CompanyIds = CompanyIds;
      params.OpenSeaID = this.Id
      await this.onProtectApi(params);
    },
    async onDispatch(data) {
      const { CompanyIds, SelectId } = data
      const p = { CompanyIds, UserId: SelectId, OpenSeaID: Number(this.OpenSeaId) }
      if (SelectId === 0) {
        this.$message.warning("请选择业务员");
        return
      }
      const ret = await distribution(p)
      if (ret === 'success') {
        this.$message.success("分配成功");
      }
      await this.onSearch();
    },
    async onTransfer(data) {
      const { CompanyIds, SelectId } = data
      const p = { CompanyIds, ToOpenSeaID: SelectId, OpenSeaID: Number(this.Id) }
      if (SelectId === 0) {
        this.$message.warning("请选择公海");
        return
      }
      const ret = await transfer(p)
      if (ret === 'success') {
        this.$message.success("转移成功");
      }
      await this.onSearch();
    },
    //点击按钮事件
    async onAllocation(type, data) {
      const selectRow = this.$refs.list.getSelectedRows();
      if (selectRow.length === 0 && !data) {
        this.$message.warning("请选择数据进行操作");
        return
      }
      let newList = [];
      if (data) {
        newList = [data].map((x) => ({
          Id: x.Id,
          Name: x.CompanyName,
          IsChoice: true,
        }));
      } else {
        const selectRow = this.$refs.list.getSelectedRows();
        newList = selectRow.map((x) => ({
          Id: x.Id,
          Name: x.CompanyName,
          IsChoice: true,
        }));
      }
      const editItem = {
        itemSource: newList,
        typeName: type
      };
      await this.$refs.editView.onOpen(editItem);
    },
    async onValueList() {
      return await getAllClientList();
    },
    //弹窗修改保存事件
    async onEditConfig(data) {
      data.OpenSeaPoolID = this.Id
      const { ClickType } = data
      if (ClickType === '分配') {
        await this.onDispatch(data)
      } else if (ClickType === '转移') {
        await this.onTransfer(data)
      } else {
        const ret =  await editButttonSeaClient(data);
        if (ret === 'success') {
          this.$message.success("清洗成功");
        } else {
          this.$message.warning("清洗失败");
        }
      }
      // if(result){
      //   this.$message.success(data.ClickType + '客户成功！');
      // }
      //await this.onSearch(this.pagination.page);
    },
    //表格初始化
    async initColumns() {
      const self = this;
      const handleColumn = columns.find((item) => item.field === "operation");
      if (handleColumn) {
        handleColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      const frontDutyColumn = columns.find((item) => item.field === "FrontDuty");
      const isFrontduty = await this.can("common_list:frontduty")
      if(frontDutyColumn && !isFrontduty) {
        this.columns = this.columns.filter((item) => item.field !== "FrontDuty")
      }
      const newColumns = await this.sort(this.columns, TableName)
      this.$refs.list.changeColumns(newColumns);
    },
    setButtons(params) {
      let data = params.data;
      let buttons = [];
      let self = this;
      const protectBtn = {
        text: "保护",
        click() {
          self.onTableProtect(data);
        },
      };
      const distributionBtn = {
        text: "分配",
        click() {
          self.onAllocation("分配", data);
        },
      };
      const transferBtn = {
        text: "转移",
        click() {
          self.onAllocation("转移", data);
        },
      };
      const cleanBtn = {
        text: "清洗",
        click() {
          self.onAllocation("清洗", data);
        },
      };

      buttons.push(protectBtn);
      buttons.push(distributionBtn);
      buttons.push(transferBtn);
      buttons.push(cleanBtn);
      return buttons;
    },
    //重置
    onResetData() {
      Object.assign(this.$data.searchData, this.$options.data().searchData);
    },

    async onColumnMoved(col) {
      if (col.column) {
        const { field, ColumnCode } = col.column.colDef
        const columns = this.$refs.list.$refs.table.columnDefs
        const localColumns = columns.map(a => { return { ...a, ColumnCode: a.field, ColumnName: a.headerName } })
        const newColumns = [...localColumns]
        const index = newColumns.findIndex(a => a.ColumnCode === (field || ColumnCode))
        if (index >= 0) {
          const _col = newColumns[index]
          newColumns.splice(index, 1)
          newColumns.splice(col.toIndex,0, _col)
          await this.onUserColumnMoved(newColumns, TableName)
        }
      }
    }
  },
  watch: {
    Id() {
      this.onSearch();
    }
  },
  created() {
    this.searchData.OpenSeaId = this.Id
    this.OpenSeaId = this.Id
    this.onSearch();
  },
  async mounted() {
    await this.initColumns();
  },
};
</script>

<style lang="scss">
.p-general {
  padding: 0;
  height: 81vh;

  .m-grid-search {
    height: 30px;
  }

  .p-list-search {
    font-size: 12px;
    width: 100%;
    display: flex;
    margin-bottom: 5px;

  }

  .p-list-search .znl-component-input {
    margin-right: 5px;
  }

  .p-input-search {
    line-height: 35px;
  }

}
  .el-date-range-picker .el-picker-panel__body{
    display: flex;
  }
</style>
